/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import {Badge, Button, Table, Tabs, TextInput} from 'flowbite-react';
import './userDetail.scss';
import {useTranslation} from 'react-i18next';
import {
    HiOutlineBadgeCheck,
    HiOutlineCalendar,
    HiOutlineClipboard,
    HiOutlineDuplicate,
    HiOutlineMail,
    HiOutlineOfficeBuilding,
    HiOutlinePencil,
    HiOutlineTrash,
    HiOutlineX,
    HiPaperAirplane
} from 'react-icons/hi';
import moment from 'moment';
import OrganizationItem from 'components/organizationItem';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {checkPermission, copyToClipboard, messageErrors, checkOrgAdmin} from 'utils/utils';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    getUserById,
    removeUser,
    removeUserOrgRole,
    updateOrganizationUser,
    updateSiteUser,
    updateUserImage
} from 'api/userApi';
import {concat, filter, find, flatten, flattenDeep, isEmpty, map, toLower, uniq} from 'lodash';
import {toast} from 'react-toastify';
import {
    acceptImageTypeFile,
    defaultpageCount,
    IMAGE_TYPE_REGEX,
    pageType,
    permissionKeys,
    routePaths
} from 'utils/constants';
import {useSelector} from 'react-redux';
import {orgSelector, userSelector} from 'redux/selectors';
import useUser from 'hooks/useUser';
import Actions from 'components/actions';
import ActionTable from 'components/table/actionTable';
import {themeTabs} from 'utils/theme';
import AssignRolesModal from 'components/modal/assignRolesModal';
import NoResult from 'components/commonComponent/noResult';
import Avatar from 'components/GroupAvatar/Avatar';
import {locationTypes, regionTypes} from 'utils/proptypes';
import GroupButtonWithIcons from 'components/button/buttonGroupWithIcons';
import {TreeTable} from 'primereact/treetable';
import PaginateTable from 'components/table/paginate';
import CustomPaginatorTreeTable from 'components/table/customPaginateTreeTable';
import {PaginatorTemplate} from 'primereact/paginator';
import {Column} from 'primereact/column';
import {removeUserLocationById, updateLocationById} from 'api/locationApi';
import AssignLocationModal from 'components/modal/locationModal/assignLocationModal';
import AssignRegionModal from 'components/modal/regionModal/assignRegionModal';
import {getRegionById, removeUserRegionById, updateRegionsByIds} from 'api/regionApi';

const UserDetail = (props: any) => {
    const {type} = props;
    const {WRITE_USER, PLATFORM_ADMIN} = permissionKeys;
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const params: any = useParams();
    const {userInfo} = useSelector(userSelector);
    const {organizationId} = useSelector(orgSelector);
    const {fetchCurrentUser} = useUser();
    const [openModal, setOpenModal] = useState(false);
    const [openModalLocation, setOpenModalLocation] = useState(false);
    const [openModalRegion, setOpenModalRegion] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchRegionValue, setSearchRegionValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalEntities, setTotalEntities] = useState(0);
    const [user, setUser] = useState<any>(null);
    const [roles, setRoles] = useState<any>([]);
    const [locations, setLocations] = useState<any>([]);
    const [regions, setRegions] = useState<any>([]);
    const [file, setFile] = useState<any>();
    const [t] = useTranslation();
    const [enableEdit, setEnableEdit] = useState(false);
    const {
        data,
        refetch
    } = useQuery(['getUserDetail', params?.id], () => getUserById(params.id), {staleTime: Infinity});
    const platformAdmin = find(uniq(flattenDeep(map(roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
    const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);
    const [tab, setTab] = useState(location?.state?.tab || 'detail');
    const [nodes, setNodes] = useState([]);
    const [nodesItemPage, setNodesItemPage] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState<any>([]);
    const [pageCount, setPageCount] = useState(defaultpageCount);
    const [rows, setRows] = useState(pageCount);
    const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
    const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

    const getRoles = (user: any) => {
        const siteRoles = user?.roles;
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const orgRoles = userOrg?.roles;
        return type === pageType.SITE ? siteRoles : orgRoles;
    };

    const getLocations = (user: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const orgLocation = userOrg?.location;

        if (orgLocation?.regionId) {
            getRegionById(orgLocation?.regionId).then(res => {
                orgLocation.region = res?.data;
            });
        }
        const locationLits: any = [];
        if (orgLocation) locationLits.push(orgLocation);
        return locationLits;
    };

    const getRegions = (user: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const orgRegions = userOrg?.regions;
        return orgRegions;
    };

    const ValidateSchema = Yup.object().shape({
        firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
        lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    });

    const {
        formState: {errors},
        register,
        handleSubmit,
        reset,
    }: any = useForm({
        mode: 'onChange',
        resolver: yupResolver(ValidateSchema),
    });

    const handleSuccess = (message: string) => {
        toast.success(message);
        refetch();
        queryClient.invalidateQueries(['getUsers']);
        queryClient.invalidateQueries(['getOrganizationDetail']);
        queryClient.invalidateQueries(['getOrganizations']);
        if (params?.id === userInfo.id) fetchCurrentUser();
    };

    const handleSubmitUser = (data: any) => {
        return type === pageType.SITE ? updateSiteUser(data) : updateOrganizationUser(data);
    };
    const muCreateUser = useMutation(['create-update-usesOrg'], {
        mutationFn: handleSubmitUser,
        onSuccess: () => {
            handleSuccess(t('userManagementPage.editSuccessMessage'))
            reset({});
            setEnableEdit(false);
        },
        onError: async (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        },
    });

    useEffect(() => {
        if (data !== undefined) {
            setUser(data?.data);
            setRoles(getRoles(data?.data)?.filter((role: any) => role?.displayName?.includes(searchValue)));
            setLocations(getLocations(data?.data));
            setRegions(getRegions(data?.data)?.filter((r: any) => r?.name?.includes(searchRegionValue)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (user) {
            const initData = {
                firstName: user.firstName,
                lastName: user.lastName,
            };
            reset(initData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (regions?.length > 0) {
            setRegions(regions);
            setTotalEntities(regions?.length || 0);
            const tempData = regions?.map((r: any) => {
                return {...r, isRoot: true}
            });
            let nodesData = generatedTreeData(tempData || []);

            // const nodesDataTemp = searchValue === '' ? nodesData.filter((node: any) => !node.data.parentRegion) : nodesData;
            setNodes(nodesData);
            if (currentPage === 0) setNodesItemPage(nodesData.slice(currentPage, rows));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regions?.length]);

    useEffect(() => {
        if (file?.name) {
            const currentFileName = toLower(file.name);
            const formData = new FormData();
            formData.append('file', file, currentFileName);
            handleUpdateAvatar(formData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const handleSearch = (value: any) => {
        const newRoles = user.roles.filter((role: any) => toLower(role?.displayName).includes(toLower(value)));
        setRoles(newRoles);
        setSearchValue(value);
    };

    const handleSearchRegion = (value: any) => {
        setSearchRegionValue(value);
        setRegions(getRegions(data?.data)?.filter((r: any) => r?.name?.includes(searchRegionValue)));
    };

    const handleAssignRoles = (rolesSelect: any) => {
        const selectRoleIds = map(flatten([rolesSelect]), 'value');
        const roleIds = type === pageType.SITE ? concat(map(user.roles, 'id'), selectRoleIds) : selectRoleIds;
        setOpenModal(false);
        return muCreateUser.mutate({id: user.id, payload: {roleIds}});
    };

    const removeAssignRoles = async (role: any, type: any) => {
        if (type === pageType.SITE) {
            const roleIds = map(filter(user.roles, (r: any) => r.id !== role?.id), 'id');
            return muCreateUser.mutate({id: user.id, payload: {roleIds}});
        } else {
            const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
            const orgRoles = userOrg?.roles;
            const roleOrgIds = orgRoles?.map((r: any) => {
                return r.id
            });
            const roleIds = roleOrgIds.filter((x: any) => x === role.id)
            const res = await removeUserOrgRole({id: user.id, payload: {roleIds}});
            if (res?.status === 200 || res?.status === 204) {
                handleSuccess(t('userManagementPage.editSuccessMessage'))
                reset({});
                setEnableEdit(false);
            } else {
                const message: string = `${res?.data?.errors[0].detail}`;
                toast.error(message);
            }
        }
    };

    const onSubmit = (value: any) => {
        const payload = {
            ...value,
            firstName: isEmpty(value.firstName) ? null : value.firstName,
            lastName: isEmpty(value.lastName) ? null : value.lastName,
        };
        muCreateUser.mutate({payload, id: params?.id});
    };

    const handleUploadImage = async (e: any) => {
        const currentFile = e.target.files[0];

        if (currentFile) {
            const currentFileName = currentFile.name
                ?.split('.')
                .map((item: string) => item.toLowerCase())
                .join('.');
            if (!IMAGE_TYPE_REGEX.test(currentFileName) && currentFile.size > 8000000) {
                const message: string = t('userManagementPage.errorSizeImage');
                toast.error(message);
                return;
            } else {
                if (!IMAGE_TYPE_REGEX.test(currentFileName)) {
                    const message: string = t('userManagementPage.errorTypeImage');
                    toast.error(message);
                    return;
                }
                if (currentFile.size > 8000000) {
                    const message: string = t('userManagementPage.errorSizeImage');
                    toast.error(message);
                    return;
                }
            }
            currentFile.isUploaded = true;
            setFile(currentFile);
        }
    };

    const handleUpdateAvatar = async (formData: any) => {
        const {data} = await updateUserImage({id: params?.id, file: formData});
        if (data) handleSuccess(t('userManagementPage.editImageSuccessMessage'))
    };

    const handleCloseForm = () => {
        setEnableEdit(false);
        reset();
    };

    const mutRemoveUser = useMutation(['removeUser'], {
        mutationFn: removeUser,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const handleRemove = (id: string) => {
        mutRemoveUser.mutate(id, {
            onSuccess: async () => {
                const message: string = t('userManagementPage.removeSuccessMessage');
                toast.success(message);
                await fetchCurrentUser();
                const permissionsSite = uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions)));
                if (id === userInfo.id && !permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) {
                    navigate(routePaths.DASHBOARD_PAGE);
                } else {
                    navigate(routePaths.ORG_USERS_PAGE);
                }
                queryClient.invalidateQueries(['getOrganizationDetail']);
                queryClient.invalidateQueries(['getOrganizations']);
                queryClient.invalidateQueries(['getUsers']);
            },
        });
    };

    const handleRemoveLocation = async (id: string, user: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const res = await removeUserLocationById(id, userOrg?.userOrganizationId);
        if (res?.status === 200 || res?.status === 204) {
            handleSuccess(t('userManagementPage.editSuccessMessage'))
            reset({});
            setEnableEdit(false);
            refetch();
        } else {
            const message: string = `${res?.data?.errors[0].detail}`;
            toast.error(message);
        }
    };

    const handleAddLocation = async (payload: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const location = payload.value;
        if (location.userOrgs?.length > 0) {
            const userLocationAdd = location.userOrgs.find((x: any) => x.id === userOrg.id);
            if (!userLocationAdd) location.userOrgs.push({id: userOrg.userOrganizationId});
        } else {
            location.userOrgs = [];
            location.userOrgs.push({id: userOrg.userOrganizationId});
        }

        const res = await updateLocationById(location.id, location);
        if (res?.status === 200 || res?.status === 204) {
            handleSuccess(t('userManagementPage.editSuccessMessage'))
            reset({});
            setEnableEdit(false);
            refetch();
        } else {
            const message: string = `${res?.data?.errors[0].detail}`;
            toast.error(message);
        }
    }

    const handleRemoveRegion = async (id: string, user: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const res = await removeUserRegionById(id, userOrg?.userOrganizationId);
        if (res?.status === 200 || res?.status === 204) {
            handleSuccess(t('userManagementPage.editSuccessMessage'))
            reset({});
            setEnableEdit(false);
            refetch();
        } else {
            const message: string = `${res?.data?.errors[0].detail}`;
            toast.error(message);
        }
    };

    const handleAddRegion = async (payload: any) => {
        const userOrg = user?.organizations?.find((o: any) => o.id === organizationId);
        const regionsIds = payload.map((r: any) => r.value);
        const res = await updateRegionsByIds(regionsIds, userOrg.userOrganizationId);
        if (res?.status === 200 || res?.status === 204) {
            handleSuccess(t('userManagementPage.editSuccessMessage'))
            reset({});
            setEnableEdit(false);
            refetch();
        } else {
            const message: string = `${res?.data?.errors[0].detail}`;
            toast.error(message);
        }
    }

    const generatedTreeData = (treeData: any) => {
        let treeNodes = [];
        treeNodes = treeData?.map((tree: any) => {
            return {
                key: `${tree.id}`,
                data: tree,
                children: generatedTreeData(tree.children)
            }
        });
        return treeNodes;
    }

    useEffect(() => {
        if (location.state) {
            setTab(location.state?.tab);
            window.history.replaceState({}, document.title);
        }
        // eslint-disable-next-line
    }, [location.key]);

    useEffect(() => {
        if (nodes) {
            const page = currentPage + 1;
            const startIndex = (page - 1) * rows;
            const endIndex = startIndex + rows;
            const nodesItemPage = nodes.slice(startIndex, endIndex);
            setNodesItemPage(nodesItemPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const expandNode = (key: any) => {
        let _expandedKeys = {...expandedKeys};

        if (_expandedKeys[key]) delete _expandedKeys[key];
        else _expandedKeys[key] = true;

        setExpandedKeys(_expandedKeys);
    };

    const actionTemplate = (key: string, data: any) => {
        if (isWriteOrg && data.isRoot === true) {
            return (
                <GroupButtonWithIcons
                    className="items-center justify-end"
                    buttons={[
                        {
                            type: 'button',
                            text: '',
                            classType: 'white',
                            icon: <HiOutlineTrash size={"20px"}/>,
                            action: () => {
                                handleRemoveRegion(key, user)
                            },
                        },
                    ]}
                />
            )
        }
    };


    const groupHeaderTemplate = () => {
        return (
            <div className="flex items-center text-xs uppercase text-gray-900 font-medium cursor-pointer">
                {t('regionPage.region')}
            </div>
        )
    };

    return (
        <div className="flex flex-col user-detail-container w-full">
            {user && (
                <>
                    {/*Banner*/}
                    <div className="user-summary w-full p-6 flex">
                        <div className="mr-6 flex items-center user-avatar-container relative">
                            <Avatar item={user} className="user-avatar h-32 w-32 text-5xl"/>
                            {isWriteOrg && (
                                <div className="user-avatar-edit flex absolute">
                                    <label htmlFor="dropzone-file-user-detail">
                                        <HiOutlinePencil className="w-10 h-10 text-white z-2 edit-icon"
                                                         strokeWidth={1.5}/>
                                        <input id="dropzone-file-user-detail" type="file" accept={acceptImageTypeFile}
                                               onChange={e => handleUploadImage(e)} className="hidden"/>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col justify-center">
                            <div className="flex mb-3 items-center name-status">
                                <p className="mr-3 text-3xl	font-semibold ">{`${user.firstName} ${user.lastName}`}</p>
                                {user?.isActive ? (
                                    <Badge color="success" className="badge-active px-2.5">
                                        {t('active')}
                                    </Badge>
                                ) : (
                                    <Badge className="badge-inactive px-2.5">{t('inactive')}</Badge>
                                )}
                            </div>
                            <div className="flex user-id">
                                <p className="mr-2 mt-2">User ID</p>
                                <p className="bg-gray-100 px-2 rounded">{user?.id}</p>
                            </div>
                        </div>
                    </div>
                    {/*Menu User Detail*/}
                    <div className="user-infor h-full relative">
                        {/* eslint-disable-next-line  */}
                        <Tabs.Group aria-label="Tabs with underline" style="underline" className="tab-button"
                                    theme={themeTabs}>
                            {/*Detail*/}
                            <Tabs.Item active={tab === 'detail'} title="DETAILS">
                                <div className="user-detail w-full h-full">
                                    {type === pageType.ORGANIZATION && <>
                                        <ActionTable
                                            buttonName={t('remove')}
                                            handleAddClick={isWriteOrg && (() => {
                                                handleRemove(user.id);
                                            })}
                                            hiddenSearch={true}
                                        />
                                    </>}
                                    <div className="user-detail-infor flex flex-row p-5 justify-between">
                                        <div className="flex flex-col user-detail-infor-side">
                                            <div className="flex items-center user-detail-infor-row">
                                                <div className="flex items-center user-label">
                                                    <HiOutlineClipboard className="w-5 h-5 mr-2 text-gray-500"/>
                                                    <p className="text-gray-500 "> {t('userManagementPage.fullName')}</p>
                                                </div>
                                                <div className={`${enableEdit && 'hidden'} flex !p-0`}>
                                                    <p className="text-gray-900 mr-2 flex items-center user-name">{`${user?.firstName} ${user?.lastName}`}</p>
                                                    {isWriteOrg && (
                                                        <div
                                                            data-testid={`test-edit-user`}

                                                            className="bg-gray-100 flex items-center w-7 h-7 justify-center rounded-sm	cursor-pointer "
                                                            onClick={() => setEnableEdit(true)}
                                                        >
                                                            <HiOutlinePencil className="w-4 h-4 text-gray-900"/>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={`${!enableEdit && 'hidden'} flex edit-full-name`}>
                                                    <form onSubmit={handleSubmit(onSubmit)}
                                                          className="w-full flex items-start edit-full-name-form">
                                                        <div
                                                            className={`${errors.firstName && 'border-error'} mr-2 input-name`}>
                                                            <TextInput id="firstName" type="text"
                                                                       placeholder="Karen" {...register('firstName')}
                                                                       className="rounded"/>
                                                            {errors.firstName && (
                                                                <div
                                                                    className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.firstName?.message}</div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`${errors.lastName && 'border-error'} mr-3 input-name`}>
                                                            <TextInput id="lastName" type="text"
                                                                       placeholder="Nelson" {...register('lastName')}
                                                                       className="rounded"/>
                                                            {errors.lastName && (
                                                                <div
                                                                    className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.lastName?.message}</div>
                                                            )}
                                                        </div>
                                                        <div className="flex ">
                                                            <Button data-testid="test-submit-edit-user" type="submit"
                                                                    className="bg-blue-600 hover:bg-blue-600 mr-3 save-button rounded w-11">
                                                                <HiPaperAirplane className="w-5 h-5 rotate-90"/>
                                                            </Button>
                                                            <Button
                                                                onClick={handleCloseForm}
                                                                className="cancel-button w-12 bg-white hover:bg-white rounded border-blue-600 text-blue-600 w-11"
                                                            >
                                                                <HiOutlineX className="w-5 h-5 text-blue-600"/>
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="flex items-center user-detail-infor-row">
                                                <div className="flex items-center user-label">
                                                    <HiOutlineMail className="w-5 h-5 mr-2 text-gray-500"/>
                                                    <p className="text-gray-500 "> {t('userManagementPage.email')}</p>
                                                </div>

                                                <p className="text-gray-900 break-all user-value">{user.emailAddress}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col user-detail-infor-side">
                                            <div className="flex items-center user-detail-infor-row">
                                                <div className="flex items-center user-label">
                                                    <HiOutlineCalendar className="w-5 h-5 mr-2 text-gray-500"/>
                                                    <p className="text-gray-500 flex items-center"> {t('userManagementPage.createdAt')}</p>
                                                </div>
                                                <p className="text-gray-900 flex items-center">{moment(user.created).format('MMMM Do YYYY, h:mm:ss A')}</p>
                                            </div>
                                            <div className="flex items-center user-detail-infor-row">
                                                <div className="flex items-center user-label">
                                                    <HiOutlineCalendar className="w-5 h-5 mr-2 text-gray-500"/>
                                                    <p className="text-gray-500 flex items-center"> {t('userManagementPage.lastedLogin')}</p>
                                                </div>

                                                <p className="text-gray-900 flex items-center">
                                                    {user?.lastLogin && moment(user?.lastLogin).format('MMMM Do YYYY, h:mm:ss A')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tabs.Item>

                            {/*Role*/}
                            <Tabs.Item active={tab === 'assignRole'} title={type === pageType.SITE ? "ROLES" : "ROLES"}
                                       className="h-full">
                                <div className="h-full">
                                    <ActionTable
                                        placeholderSearch={t('roleManagementPage.placeholderSearch')}
                                        buttonName={t(type === pageType.SITE ? 'assignRoles' : 'assignRoles')}
                                        handleAddClick={isWriteOrg && (() => setOpenModal(!openModal))}
                                        handleSearch={handleSearch}
                                        hiddenSearch={type === pageType.ORGANIZATION}
                                    />
                                    {getRoles(user)?.length === 0 && (
                                        <div className="p-8 mx-8 bg-white border border-gray-200 rounded-lg">
                                            <div className="mb-4 flex justify-center">
                                                <HiOutlineBadgeCheck size={80} strokeWidth={1} color="#D1D5DB"
                                                                     fontWeight={100}/>
                                            </div>
                                            <p className="text-xl font-semibold text-gray-900 text-center mb-1.5">{t('userDetail.notificationNotRoles')}</p>
                                            <p className="text-base font-normal text-gray-900 text-center	">{t('userDetail.noteNotRoles')}</p>
                                        </div>
                                    )}

                                    {getRoles(user)?.length > 0 && roles?.length === 0 && <NoResult/>}
                                    {getRoles(user)?.length > 0 && roles?.length > 0 && (
                                        <Table className="border-b">
                                            <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium px-3 py-0 pb-3">{t('roleManagementPage.Table.ColumnColName')}</Table.HeadCell>
                                                <Table.HeadCell
                                                    className="bg-white font-medium  hidden-mobile-tablet py-0 pb-3">{t('description')}</Table.HeadCell>
                                                <Table.HeadCell
                                                    className="bg-white font-medium w-6 pb-[10px]">{''}</Table.HeadCell>
                                            </Table.Head>
                                            <Table.Body>
                                                {roles.map((role: any) => (
                                                    <Table.Row key={role?.id}
                                                               className="cursor-pointer bg-white text-sm hover:bg-gray-100 border-b">
                                                        <Table.Cell
                                                            className="text-gray-900 font-semibold py-[15px] px-3">
                                                            <div className="flex flex-row items-center">
                                                                <div
                                                                    className="text-secondary">{`${role?.displayName || ''}`}</div>
                                                                <div className="copy-icon pl-3 cursor-pointer">
                                                                    <HiOutlineDuplicate size={25}
                                                                                        onClick={() => copyToClipboard(role?.displayName, t('roleManagementPage.copiedName'))}/>
                                                                </div>
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="text-gray-900 py-[15px] hidden-mobile-tablet">{role?.description}</Table.Cell>
                                                        <Table.Cell className="py-[15px]">
                                                            {(type === pageType.SITE || type === pageType.ORGANIZATION) && roles.length > 1 && (
                                                                <Actions>
                                                                    <Actions.Item
                                                                        show
                                                                        icon={<HiOutlineTrash className="w-5 h-5"/>}
                                                                        action={() => !muCreateUser.isLoading && removeAssignRoles(role, type)}
                                                                    />
                                                                </Actions>
                                                            )}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    )}
                                </div>
                                <AssignRolesModal
                                    headerTitle={t(type === pageType.SITE ? 'assignRoles' : 'assignRole')}
                                    openModal={openModal}
                                    setOpenModal={setOpenModal}
                                    queryClient={queryClient}
                                    roles={getRoles(user)}
                                    type={type}
                                    assignRoles={handleAssignRoles}
                                />
                            </Tabs.Item>

                            {/*Organization*/}
                            <Tabs.Item active={tab === 'organizations'} title="ORGANIZATIONS">
                                <div className="user-detail-org p-5">
                                    {user?.organizations.length === 0 ? (
                                        <div className="h-full pt-2">
                                            <div className="mb-4 flex justify-center">
                                                <HiOutlineOfficeBuilding className="w-20 h-20 text-gray-200"
                                                                         strokeWidth={1}/>
                                            </div>
                                            <p className="text-xl font-semibold text-center mb-1">{t('userDetail.haveNoOrgNotification')}</p>
                                            <p className="text-base font-normal text-center mb-2	">{t('userDetail.note')}</p>
                                        </div>
                                    ) : (
                                        <div className="organization w-full grid grid-cols-4 gap-4 justify-start">
                                            {user?.organizations?.map((item: any) => {
                                                return <OrganizationItem key={item.id} organization={item}/>;
                                            })}
                                        </div>
                                    )}
                                </div>
                            </Tabs.Item>
                            {/*Location*/}
                            <Tabs.Item active={tab === 'location'} title="LOCATION" className="h-full">
                                <div className="h-full">
                                    <ActionTable
                                        placeholderSearch='Search'
                                        buttonName={t('locationPage.addLocation')}
                                        handleAddClick={isWriteOrg && user?.organizations?.length > 0 && (() => setOpenModalLocation(!openModalLocation))}
                                        handleSearch={() => {
                                        }}
                                        hiddenSearch={true}
                                    />
                                    {getLocations(user)?.length === 0 && locations?.length === 0 && <NoResult/>}
                                    {getLocations(user)?.length > 0 && locations?.length > 0 && (
                                        <Table className="border-b">
                                            <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs px-3 py-0 pb-3">
                                                    <div className="flex items-center">
                                                        {t('locationPage.location')}
                                                    </div>
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="cursor-pointer bg-white font-medium text-xs py-0 pb-3">
                                                    <div className="flex items-center">
                                                        {t('locationPage.clubCode')}
                                                    </div>
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="hidden-mobile-tablet bg-white font-medium text-xs py-0 pb-3">
                                                    <div className="flex items-center">
                                                        {t('locationPage.region')}
                                                    </div>
                                                </Table.HeadCell>
                                                <Table.HeadCell
                                                    className="hidden-mobile w-44 bg-white font-medium text-xs py-0 pb-3">
                                                    <div className="flex items-center">
                                                        {t('locationPage.status').toUpperCase()}
                                                    </div>
                                                </Table.HeadCell>
                                                <Table.HeadCell className="w-6 bg-white">{''}</Table.HeadCell>
                                            </Table.Head>
                                            <Table.Body>
                                                {locations?.map((item: locationTypes, index: any) => (
                                                    <Table.Row key={`row-${index + 1}`}
                                                               className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                        <Table.Cell className="font-semibold py-[6px] px-3">
                                                            <div className="flex flex-row items-center">
                                                                <div className="text-secondary">{item?.name}</div>
                                                                <div
                                                                    className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                    <HiOutlineDuplicate size={25}
                                                                                        onClick={(e: any) => copyToClipboard(item?.name, t('locationPage.copiedName'))}/>
                                                                </div>
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell className="py-[6px]">
                                                            <div className="flex flex-row items-center">
                                                                <div className="text-gray-900">{item?.clubCode}</div>
                                                                <div
                                                                    className="copy-icon pl-3 cursor-pointer text-gray-900">
                                                                    <HiOutlineDuplicate size={25}
                                                                                        onClick={(e: any) => copyToClipboard(item?.clubCode, t('locationPage.copiedExternalId'))}/>
                                                                </div>
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell className="py-[6px]">
                                                            <div className="flex flex-row items-center">
                                                                <div
                                                                    className="text-gray-900">{item?.region?.name}</div>
                                                                {item?.region?.name && <div
                                                                    className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                    <HiOutlineDuplicate size={25}
                                                                                        onClick={(e: any) => copyToClipboard(item?.region?.name, t('locationPage.copiedGroupName'))}/>
                                                                </div>}
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell className="text-gray-900 hidden-mobile py-[8px]">
                                                            <aside className="flex flex-row items-center">
                                              <span
                                                  className={`w-3 h-3 flex ${item?.isActive ? 'bg-green-400' : 'bg-red-500'} rounded-lg mr-2`}/>
                                                                {t(item?.isActive ? 'active' : 'inactive')}
                                                            </aside>
                                                        </Table.Cell>
                                                        <Table.Cell className="py-[6px]">
                                                            {isOrgAdmin && (
                                                                <GroupButtonWithIcons
                                                                    className="items-center justify-center"
                                                                    buttons={[
                                                                        {
                                                                            type: 'button',
                                                                            text: '',
                                                                            classType: 'white',
                                                                            icon: <HiOutlineTrash size={"20px"}/>,
                                                                            action: () => {
                                                                                handleRemoveLocation(item?.id, user);
                                                                            },
                                                                        },
                                                                    ]}
                                                                />
                                                            )}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    )}
                                </div>
                                <AssignLocationModal
                                    headerTitle={t('locationPage.addLocation')}
                                    openModal={openModalLocation}
                                    setOpenModal={setOpenModalLocation}
                                    queryClient={queryClient}
                                    addLocation={handleAddLocation}
                                    locations={locations}
                                />
                            </Tabs.Item>

                            {/*Region*/}
                            <Tabs.Item active={tab === 'regions'} title="REGIONS" className=" h-full">
                                <div className="h-full">
                                    <ActionTable
                                        placeholderSearch='Search'
                                        buttonName={t('regionPage.addRegion')}
                                        handleAddClick={isWriteOrg && user?.organizations?.length > 0 && (() => {setOpenModalRegion(!openModalRegion)})}
                                        handleSearch={handleSearchRegion}
                                        hiddenSearch={false}
                                    />
                                    {(!regions || regions?.length === 0) && (<NoResult/>)}
                                    {getRegions(user)?.length > 0 && regions?.length > 0 && (
                                        <>
                                            <Table className="border-b">
                                                <Table.Head className="text-xs text-gray-900 border-b-2 border-gray-100">
                                                    <Table.HeadCell
                                                        className="cursor-pointer bg-white font-medium text-xs px-3 py-0 pb-3">
                                                        <div className="flex items-center">
                                                            {t('regionPage.region').toUpperCase()}
                                                        </div>
                                                    </Table.HeadCell>
                                                    <Table.HeadCell className="w-6 bg-white">{''}</Table.HeadCell>
                                                </Table.Head>
                                                <Table.Body>
                                                    {regions?.map((item: regionTypes, index: any) => (
                                                        <Table.Row key={`row-${index + 1}`}
                                                                  className="cursor-pointer bg-white text-sm hover:bg-gray-50 border-b border-gray-100">
                                                            <Table.Cell className="font-semibold py-[6px] px-3">
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-secondary">{item?.name}</div>
                                                                    <div
                                                                        className="copy-icon pl-3 cursor-pointer text-gray-800">
                                                                        <HiOutlineDuplicate size={25}
                                                                                            onClick={(e: any) => copyToClipboard(item?.name, t('locationPage.copiedName'))}/>
                                                                    </div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className="py-[6px]">
                                                                {isOrgAdmin && (
                                                                    <GroupButtonWithIcons
                                                                        className="items-center justify-center"
                                                                        buttons={[
                                                                            {
                                                                                type: 'button',
                                                                                text: '',
                                                                                classType: 'white',
                                                                                icon: <HiOutlineTrash size={"20px"}/>,
                                                                                action: () => {
                                                                                    handleRemoveRegion(item?.id, user);
                                                                                },
                                                                            },
                                                                        ]}
                                                                    />
                                                                )}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                            <PaginateTable
                                                setCurrentPage={setCurrentPage}
                                                currentPage={currentPage}
                                                totalEntities={regions?.length}
                                                isLoadingTable={true}
                                            />
                                        </>
                                    )}
                                </div>
                                <AssignRegionModal
                                    headerTitle={t('regionPage.addRegion')}
                                    openModal={openModalRegion}
                                    setOpenModal={setOpenModalRegion}
                                    queryClient={queryClient}
                                    regions={regions}
                                    addRegion={handleAddRegion}
                                />
                            </Tabs.Item>
                        </Tabs.Group>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserDetail;
