import { useEffect, useState } from 'react';
import './style.scss';
import { isArray, isEmpty, map } from 'lodash';
import ReactSelect, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import AvatarCustom from 'components/GroupAvatar/Avatar';
const animatedComponents = makeAnimated();

const Select = (props: any) => {
  const { name, placeholder, errors, isHolderLabel, onChange, options, value, formatOption , isMulti = false, menuIsOpen, isCustomOption = false, hookForm, className = '', isShowImage = false, isDisabled = false, filterOption, customStyles } = props;
  const getValue = () => value || options?.filter((o: any) => hookForm?.getValues(name)?.includes(o.value));
  const [focus, setFocus] = useState(!isEmpty(getValue()));
  const fnErrors = errors || hookForm?.formState?.errors || {};

  // const Option = ({ props, label, data } : any) => (
  //   <div {...props}>
  //     <img src={data.value?.url || data.value?.fileImage?.s3Path} alt={label} style={{ width: '20px', height: '20px', marginRight: '8px' }} /> {label}
  //     {/* <AvatarCustom item={data.value}/> {label} */}
  //   </div>
  // );

  const formatOptionLabel = ({ label, value }: any) => {
    return (
      <>
        <AvatarCustom item={value}/> {label}
      </>
    );
  };
  
  // animatedComponents.Option = Option;

  const handleOnChange = (options: any) => {
    if (hookForm) {
      const values: any = options ? (isArray(options) ? map(options, 'value') : options.value) : [];
      hookForm.setValue(name, values);
      if (!isEmpty(values) && !isEmpty(hookForm.formState.errors)) hookForm.clearErrors(name);
    }
    if (onChange) onChange(options);
  };

  useEffect(() => {
    setFocus(getValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])
  return (
    <div className={`flex-1 select-custom relative w-full ${isMulti ? 'select-multi' : isCustomOption ? 'select-multi custom-single' : ''} ${className}`}>
      {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
      {menuIsOpen ? 
      (
        <ReactSelect
          menuIsOpen
          closeMenuOnSelect={!isMulti}
          isClearable={!isMulti}
          components={animatedComponents}
          formatOptionLabel={formatOption ? formatOption : undefined}
          isMulti={isMulti}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          defaultValue={getValue()}
          options={options}
          value={value}
          onChange={handleOnChange}
          className={`react-select ${fnErrors[name] ? 'error bg-red-50' : ''}`}
          classNamePrefix="react-select"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(!isEmpty(getValue()))}
          placeholder={!isHolderLabel ? placeholder : ''}
          isDisabled={isDisabled}
          filterOption={filterOption ? filterOption : undefined}
          styles={customStyles ? customStyles : undefined}
        />
      )
      :
      (
        <ReactSelect
          closeMenuOnSelect={!isMulti}
          isClearable={!isMulti}
          components={animatedComponents}
          formatOptionLabel={formatOption ? formatOption : undefined}
          isMulti={isMulti}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          defaultValue={getValue()}
          options={options}
          value={value}
          onChange={handleOnChange}
          className={`react-select ${fnErrors[name] ? 'error bg-red-50' : ''}`}
          classNamePrefix="react-select"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(!isEmpty(getValue()))}
          placeholder={!isHolderLabel ? placeholder : ''}
          isDisabled={isDisabled}
          filterOption={filterOption ? filterOption : undefined}
          styles={customStyles ? customStyles : undefined}
        />
      )
      }
      {fnErrors[name] && <div className={`text-red-600 text-xs font-normal mt-2 veri-modal height-16`}>{fnErrors[name]?.message}</div>}
    </div>
  );
};

export default Select;
