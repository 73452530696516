import {poStatusOptions, searchMaxLength} from 'utils/constants';
import {HiOutlineCalendar, HiOutlineSearch, HiOutlineX} from 'react-icons/hi';
import './actionTable.scss';
import MultiSelect from 'components/select/index';
import {useTranslation} from 'react-i18next';
import {isEmpty} from 'lodash';
import Buttons from "../button/Buttons";
import {useRef, useState} from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import a Flatpickr theme
import SelectMulti from 'components/select/multiSelect';
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
type buttonProps = {
    buttonName: string,
    buttonHidden: boolean,
    buttonAction: Function,
    className?: string,
};
const ActionTable = (props: any) => {
    const {
        placeholderSearch,
        buttonName,
        buttons,
        handleAddClick,
        exportHandler,
        buttonExports,
        handleSearch,
        searchValue,
        hiddenSearch = false,
        fDate, setFDate,
        tDate, setTDate,
        statuss, setStatuss,
        type
    } = props;
    const [t] = useTranslation();
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusTypeOptions, setStatusTypeOptions] = useState(poStatusOptions);
    const flatpickrRef = useRef<Flatpickr | null>(null);
    const [dateFilter, setDateFilter] = useState<any>([]);

    const handleoOnKeyUp = (e: any) => {
        const {key, target} = e;
        if (key === 'Enter') handleSearch(target.value);
    };

    const handleoOnChange = (e: any) => {
        if (isEmpty(e.target.value)) handleSearch(e.target.value);
    };

    const handleChangeStatus = (options: any) => {
        setSelectedStatus(options);
        if (options?.length > 0) {
            const sta: any = [];
            for (let index = 0; index < options?.length; index++) {
                const element = options[index];
                sta.push(element?.value);
            }
            setStatuss(sta);
        } else {
            setStatuss([]);
        }
    };

    const handleChangeDate = (option: any) => {
        if (option) {
            if (option[0]) {
                setFDate((option[0] as Date).toLocaleDateString());
            }

            if (option[1]) {
                setTDate((option[1] as Date).toLocaleDateString());
            }
            setDateFilter(option);
        } else {
            setFDate('');
            setTDate('');
            setDateFilter([]);
        }
    };

    const handleClearSelection = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
            setFDate('');
            setTDate('');
        }
    };

    // @ts-ignore
    return (
        <div className="flex pb-3 justify-between action-table">
            <div className="left">
                    <div
                        className="flex items-center text-search border border-gray-200 mr-[15px] action-search rounded-sm pl-2 h-[36px]">
                        {!hiddenSearch && (
                            <span className="pl-1"><HiOutlineSearch strokeWidth={3} className="w-4 h-4"/></span>
                        )}
                        <input
                            className="px-2 text-sm border-0 focus:outline-none focus:drop-shadow-none w-full bg-transparent"
                            type="search"
                            name="search"
                            hidden={hiddenSearch}
                            maxLength={searchMaxLength}
                            placeholder={placeholderSearch}
                            onChange={e => handleoOnChange(e)}
                            onKeyUp={e => handleoOnKeyUp(e)}
                        />
                    </div>
                    {type === "poList" && (<SelectMulti
                        data={statusTypeOptions}
                        selectedData={statuss}
                        setSelectedData={setStatuss}
                        placeholder={t('itemPage.status')}
                    />)}
                {type === "poList" &&
                    (
                        <div className="custom-date-range">
                            <Flatpickr
                                ref={flatpickrRef}
                                value={dateFilter}
                                onChange={(e: any) => handleChangeDate(e)}
                                options={
                                    {
                                        mode: 'range',
                                        dateFormat: 'M-d-Y'
                                    }
                                }
                                placeholder="Date Range"
                            />
                            {fDate !== '' && tDate !== '' ?
                                <button onClick={handleClearSelection}><HiOutlineX/></button> : <HiOutlineCalendar/>}
                        </div>
                    )}
            </div>
            <div className="flex justify-end gap-3 action-button">
                {buttons
                    ? buttons.map((button: buttonProps) => {
                        return (
                            <>
                                {button.buttonHidden && (

                                    <Buttons className={button.className || 'text-white bg-secondary'} text={button.buttonName}
                                             onClick={() => button.buttonAction()}/>
                                )}
                            </>
                        );
                    })
                    : handleAddClick && (
                    <Buttons className="text-white bg-secondary" text={buttonName}
                             onClick={() => handleAddClick()}/>
                )}
                {buttonExports && (
                    buttonExports.map((buttonExport: buttonProps) => {
                        return (

                            <Buttons className="text-secondary border border-secondary"
                                     text={buttonExport.buttonName} onClick={() => buttonExport.buttonAction()}/>
                        )
                    })
                )}
            </div>
        </div>
    );
};
export default ActionTable;
