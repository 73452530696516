import SelectOrganization from 'components/selectOrg';
import UserProfile from 'components/userProfile';
import './header.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { permissionKeys, routePaths } from '../utils/constants';
import Hightlight from './highlight';
import { HiBell } from 'react-icons/hi';
import { checkOrgAdmin, checkPermission } from 'utils/utils';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { useEffect, useState } from 'react';
import { getNotificationByFilter } from 'api/notificationApi';
import { Tooltip } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line
const logo = require('../assets/image/svg/logo.svg').default;

const Header = (props: any) => {
  const { type, path } = props;
  const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
  const navigate = useNavigate();
  const {userInfo} = useSelector(userSelector);
  const location = useLocation();
  const [t] = useTranslation();
  const url = location.pathname;
  const {organizationId} = useSelector(orgSelector);
  const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
  const [notifications, setNotifications] = useState(null);
  const [count, setCount] = useState(0); 

  const {data, isLoading, refetch, error} = useQuery(
    ['getNotifications'],
    () => getNotificationByFilter(
      {page: 0, 
        limit: 0, 
        organizationId, 
        //userId: isAdmin ? null : userInfo.id, 
        isUnread: true}
      ),
    {
        staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (data) {
      setCount(data.data?.totalEntities);
    }
    // eslint-disable-next-line
  }, [userInfo, organizationId, data]);
  
  return (
    <div className="flex flex-col sm:flex-row bg-darkPrimary text-white items-center justify-between py-3 px-4 flex-wrap shadow-md header hidden-mobile">

      <div className="flex flex-1 justify-between h-12 items-center">
        <div className="flex">
          <div className="logo-container">
            <button
              className="logo pl-3 h-full w-full border-gray-200 pt-6 pb-6 flex items-center cursor-pointer hover:shadow-none"
              onClick={() => navigate(routePaths.DASHBOARD_PAGE)}
            >
              <img alt="MileMarker logo" className="mr-3" src={logo} />
            </button>
          </div>
          <div className="ml-1 flex items-center">
            <SelectOrganization type={type} />
          </div>
        </div>
        <div className="flex items-center relative">
        {isOrgAdmin && (
          <>
            <Tooltip content={t('sidebar.notification')}>
              <button className="mr-4 p-1 relative" onClick={() => navigate(routePaths.NOTIFICATION)}>
              <Hightlight count={count} className="absolute right-0 top-0" />
              <HiBell className="text-white w-7 h-7" />
              </button>
            </Tooltip>
          </>
          )}
          <UserProfile />
        </div>
      </div>
    </div>
  )
}

export default Header;
