/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import CustomModalHeader from '../customModalHeader';
import { filter, isEmpty, map } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { Label, Modal } from 'flowbite-react';
import Select from 'react-select';
import { pageType, permissionKeys, sortByTypeType } from 'utils/constants';
import '../userModal.scss';
import makeAnimated from 'react-select/animated';
import classNames from 'classnames';
import { themModal } from 'utils/theme';
import { getLocationByFilter } from 'api/locationApi';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';
const animatedComponents = makeAnimated();

const AssignLocationModal = (props: any) => {
  const { openModal, setOpenModal, headerTitle, locations, addLocation } = props;
  const [t] = useTranslation();
  const {PLATFORM_ADMIN} = permissionKeys;
  const {userInfo} = useSelector(userSelector);
  const [locationTypeOptions, setLocationTypeOptions] = useState([{label: '...', value: null}]);
  const [locationSelects, setLocationSelects] = useState([]);
  const [error, setError] = useState(false);
  const { organizationId } = useSelector(orgSelector);
  const isAdmin = checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);

  const rootRef = useRef(null);

  const getRegions = () => {
    const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
    const orgRegions = userOrg?.regions;
    let regionIds = '';
    if (orgRegions) {
        for (let index = 0; index < orgRegions.length; index++) {
            const region = orgRegions[index];
            if (regionIds === '') {
                regionIds = region.id;
            } else {
                regionIds = `${regionIds},${region.id}`;
            }
        }
    }
    return regionIds;
}

  useEffect(() => {

    getLocationByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions() })?.then(res => {
      const  newOption = res?.data?.entities?.map((i: any)=> ({label: i.name, value: i}));
      if (locations && locations?.length > 0) {
        setLocationTypeOptions(newOption?.filter((x: any) => x.value.id !== locations[0].id));
      } else {
        setLocationTypeOptions(newOption);
      }
    });

    // eslint-disable-next-line
  }, [locations]);

  const handleChange = (options: any) => {
    setLocationSelects(options);
    setError(isEmpty(options));
  };

  const handleClose = () => {
    setOpenModal(false);
    setLocationSelects([]);
    setError(false);
  };
  const handleSave = () => {
    setError(isEmpty(locationSelects));
    if (!isEmpty(locationSelects)) {
      addLocation(locationSelects);
      handleClose();
    }
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-assign-role"
        show={openModal}
        size="xl"
        root={rootRef.current ?? undefined}
        onClose={() => handleClose()}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={() => handleClose()} />
        <Modal.Body theme={{ base: 'flex-1 pb-4 pt-3 px-12' }}>
          <div className="flex flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label value="Location *" />
              </div>
              <Select
                isMulti={false}
                closeMenuOnSelect={true}
                options={locationTypeOptions}
                components={animatedComponents}
                onChange={option => handleChange(option)}
                className={classNames('react-select', { error })}
                classNamePrefix="react-select"
                noOptionsMessage={() => t('No options')}
                placeholder={'Location'}
                value={locationSelects}
              />
              {error && <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>
                {t('validations.required')}</div>}
            </div>
            <GroupButton
              className="items-center justify-center pt-1 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => handleClose(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: false,
                  action: () => handleSave(),
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AssignLocationModal;
