/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { optionType, roleType } from 'utils/proptypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createSiteUser, createOrganizationUser, getUsersByEmail, updateSiteUser, updateOrganizationUser } from 'api/userApi';
import CustomModalHeader from './customModalHeader';
import * as Yup from 'yup';
import { filter, flattenDeep, isEmpty, map } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, TextInput, Modal } from 'flowbite-react';
import Select from 'react-select';
import classNames from 'classnames';
import { pageType } from 'utils/constants';
import {HiCheck, HiOutlineMail} from 'react-icons/hi';
import './userModal.scss';
import useUser from 'hooks/useUser';
import InputText from 'components/commonComponent/inputText';
import MultiSelect from 'components/select/index';
import makeAnimated from 'react-select/animated';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
const animatedComponents = makeAnimated();

type userOrganizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle: Function,
  organizationId?: string,
  successFunc?: Function,
  isEdit: boolean,
  isEditSuperAdmin?: boolean,
  roleOptions?: [roleType],
  setIsEdit?: any,
  type?: string,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const UserModal = (props: userOrganizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, setIsEdit, roleOptions, type, queryClient, currentPage, setCurrentPage } = props;

  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const [error, setErrors] = useState(false);
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const organization = userInfo.organizations?.find((o: any) => o.id === organizationId);
  

  const rootRef = useRef(null);

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable(),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable(),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    roleIds: Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    getValues,
  }: any = hookForm;

  type userType = {
    firstName: string,
    lastName: string,
    emailAddress: string,
    roleIds: string[],
  };

  const initUser: userType = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    roleIds: [],
  };

  const initOption: optionType = { value: '', label: '' };
  const [selectedOption, setSelectedOption] = useState([]);
  const roleSelectOptions: any = roleOptions?.map((r: roleType) => ({ value: r?.id, label: r?.displayName }));
  const [emailInput, setEmailInput] = useState('');

  const { data } = useQuery(['checkEmailExist', emailInput], () => getUsersByEmail(emailInput), {
    staleTime: Infinity,
    enabled: emailInput !== '',
    retry: false,
  });
  const handleSubmitUser = (data: any) => {
    if (isEdit) {
      return type === pageType.SITE ? updateSiteUser(data) : updateOrganizationUser(data);
    } else {
      return type === pageType.SITE ? createSiteUser(data) : createOrganizationUser(data);
    }
  };

  const mutation = useMutation(['create-update-usesOrg'], { mutationFn: handleSubmitUser });

  const onSubmit = (value: any) => {
    console.log('onSubmit')
    const payload = {
      ...value,
      firstName: isEmpty(value.firstName) ? undefined : value.firstName,
      lastName: isEmpty(value.lastName) ? undefined : value.lastName,
    };
    mutation.mutate(
      { payload, id: targetData?.id },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = isEdit ? t('userManagementPage.editSuccessMessage') : t('userManagementPage.createSuccessMessage');
          toast.success(message);
          reset({});
          setSelectedOption([]);
          if (currentPage !== 0 && !isEdit) {
            queryClient.invalidateQueries('getUsers', { refetchActive: false }, { cancelRefetch: true });
            setCurrentPage(0);
          } else queryClient.invalidateQueries('getUsers', { refetchActive: true }, { cancelRefetch: true });
          queryClient.invalidateQueries('getOrganizationDetail');
          queryClient.invalidateQueries('getOrganizations');
          queryClient.invalidateQueries('getUserDetail', { refetchActive: true }, { cancelRefetch: true });
          fetchCurrentUser();
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = () => {
    reset();
    setIsEdit(false);
    setOpenModal(!openModal);
    setSelectedOption([]);
  };

  useEffect(() => {
    if (isEdit) {
      const roleIds =
        type === pageType.SITE ? targetData.roles.map((role: roleType) => role.id) : map(targetData.organizations, (o: any) => o.roleId);
      const selectedRole: any = roleSelectOptions?.filter((role: optionType) => roleIds?.find((roleId: string) => role.value === roleId)) || [
        initOption,
      ];
      setSelectedOption(selectedRole);
      const initData = {
        firstName: targetData.firstName,
        lastName: targetData.lastName,
        emailAddress: targetData.emailAddress,
        roleIds,
      };
      reset(initData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (options: any = []) => {
    const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
    setSelectedOption(noDefaultOptions);
    const roleIds = noDefaultOptions.map((option: optionType) => option.value);
    setValue('roleIds', roleIds);
    if (roleIds.length > 0) {
      clearErrors('roleIds');
    } else {
      setError('roleIds', {
        type: 'required',
        message: t('userManagementPage.requiredField'),
      });
    }
  };

  useEffect(() => {
    if (selectedOption?.length > 0) {
      clearErrors('roleIds');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (data) {
      setValue('firstName', data?.data.firstName);
      setValue('lastName', data?.data.lastName);
      clearErrors('firstName');
      clearErrors('lastName');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkEmailIsExist = () => {
    if (type === pageType.ORGANIZATION && !errors.emailAddress) {
      const email: any = getValues('emailAddress');
      setEmailInput(email);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      checkEmailIsExist();
    }
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-add-user"
        show={openModal}
        size="md"
        root={rootRef.current ?? undefined}
        onClose={() => cancelHandler()}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle()} toggle={() => cancelHandler()} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 relative">
              <InputText 
                hookForm={hookForm}
                placeholder={t('userProfilePage.email')}
                name="emailAddress"
                id="emailAddress"
                isHolderLabel={true}
                icon={<HiOutlineMail size={20}/>}
              />
              {/*{errors.emailAddress && (*/}
              {/*      <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.emailAddress?.message}</div>*/}
              {/*)}*/}
              {data && !errors.emailAddress && (
                    <div className="flex items-center">
                      <HiCheck className="w-6 h-6 mr-2 text-green-600 " />
                      <div className={`text-green-600 text-xs font-normal mt-1 flex`}>{t('userManagementPage.emailExist')}</div>
                    </div>
              )}
            </div>
            {type === pageType.SITE && (
              <>
                <div className="mt-4 relative">
                  <InputText 
                    hookForm={hookForm}
                    placeholder={`${t('userProfilePage.firstName')} *`}
                    name="firstName"
                    id="firstName"
                    isHolderLabel={true}
                  />
                  {errors.firstName && (
                        <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.firstName?.message}</div>
                    )}
                </div>
                <div className="mt-4 relative">
                  <InputText 
                    hookForm={hookForm}
                    placeholder={`${t('userProfilePage.lastName')} *`}
                    name="lastName"
                    id="lastName"
                    isHolderLabel={true}
                  />
                  {errors.lastName && (
                        <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.lastName?.message}</div>
                      )}
                </div>
              </>
            )}
            <div className="mt-4 relative">
            <MultiSelect
                id="roleIds"
                name="roleIds"
                hookForm={hookForm}
                isMulti={type === pageType.SITE || type === pageType.ORGANIZATION}
                isShowImage={false}
                options={roleSelectOptions}
                components={animatedComponents}
                onChange={(option: any) => handleChange(option)}
                className={classNames('react-select', { error })}
                classNamePrefix="react-select"
                noOptionsMessage={() => t('No options')}
                placeholder={t('roleManagementPage.RoleModal.Role')}
                value={selectedOption}
                isHolderLabel={true}
              />
                </div>
                <GroupButton
                className="items-center justify-center pt-5 mb-3"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => cancelHandler(),
                  },
                  {
                    type: 'submit',
                    text: t('modal.save'),
                    classType: 'blue',
                    isLoading: mutation.isLoading,
                  },
                ]}
              />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UserModal;
