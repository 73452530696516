/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import CustomModalHeader from 'components/modal/customModalHeader';
import {HiOutlineCalendar, HiOutlineX} from 'react-icons/hi';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import  { useEffect, useRef, useState } from 'react';
import { themModal } from 'utils/theme';
import '../../form/styles.scss';
import GroupButton from 'components/button/groupButton';
import { exportStockFormOrg, exportStockLedgerFormOrg } from 'api/stockApi';
import { useSelector } from 'react-redux';
import { locationSelector, orgSelector, userSelector } from 'redux/selectors';
import MultiSelect from 'components/select/index';
import { pageType, permissionKeys, sortByTypeType } from 'utils/constants';
import { toast } from 'react-toastify';
import { checkOrgAdmin, checkPermission, getUserOrg } from 'utils/utils';
import { getLocationByFilter } from 'api/locationApi';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import a Flatpickr theme

type exportStockLedgerModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string
};
const ExportStockLedgerModal = (props: exportStockLedgerModalProps) => {
  const { openModal, setOpenModal, headerTitle } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const rootRef = useRef(null);
  const { organizationId } = useSelector(orgSelector);
  const { locationId } = useSelector(locationSelector);
  const [selectedType, setSelectedType] = useState<any>({label: 'This Location', value: "This"});
  const [typeOptions, setTypeOptions] = useState([
            {label: 'This Location', value: "This"},
            {label: 'All My Locations', value: "All"}
        ]);
  const {WRITE_LOCATION, PLATFORM_ADMIN} = permissionKeys;
  const isAdmin = checkPermission(userInfo, pageType.LOCATION, [PLATFORM_ADMIN], organizationId);
  const isOrgAdmin = checkOrgAdmin(userInfo, organizationId, isAdmin);
  const flatpickrRef = useRef<Flatpickr | null>(null);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [fDate, setFDate] = useState('');
  const [tDate, setTDate] = useState('');
      
    
  const getRegions = () => {
        const userOrg = userInfo?.organizations?.find((o: any) => o.id === organizationId);
        const orgRegions = userOrg?.regions;
        let regionIds = '';
        if (orgRegions) {
            for (let index = 0; index < orgRegions.length; index++) {
                const region = orgRegions[index];
                if (regionIds === '') {
                    regionIds = region.id;
                } else {
                    regionIds = `${regionIds},${region.id}`;
                }
            }
        }
        return regionIds;
    }

    const handleChangeDate = (option: any) => {
      if (option) {
        if (option[1]) {
          setFDate((option[0] as Date).toLocaleDateString());
          setTDate((option[1] as Date).toLocaleDateString());
          setDateFilter(option);
      }
      } else {
          setFDate('');
          setTDate('');
          setDateFilter([]);
      }
    };
    
    const handleClearSelection = () => {
      if (flatpickrRef.current) {
          flatpickrRef.current.flatpickr.clear();
          setFDate('');
          setTDate('');
      }
    };

  const toggleHandler = () => {
    setOpenModal(!openModal);
  };

  const handleExportItem = async () => {
    setSelectedType(selectedType);
    if (selectedType?.value === "This") {
        const res = await exportStockLedgerFormOrg(organizationId, { locationId, fDate, tDate });
        if (res.data || res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `stock-ledger-export.xlsx`);
            document.body.appendChild(link)
            link.click();
    
            // Remove dom after finish
            document.body.removeChild(link);
          }
    } else {
      let ids = '';
            const resLocation = await getLocationByFilter({ page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId, userOrgId: isOrgAdmin === true ? null : getUserOrg(userInfo, organizationId)?.userOrganizationId, regionIds: isOrgAdmin === true ? '' : getRegions(), isActive: true });
            if (resLocation.data) {
                for (let index = 0; index < resLocation.data?.entities?.length; index++) {
                    const element = resLocation.data?.entities[index];
                    if (ids === '') {
                        ids = element.id;
                    } else {
                        ids = `${ids},${element.id}`;
                    }
                }
            }
            const res = await exportStockLedgerFormOrg(organizationId, { locationIds: ids, fDate, tDate });
            if (res.data || res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `stock-ledger-export.xlsx`);
                document.body.appendChild(link)
                link.click();
        
                // Remove dom after finish
                document.body.removeChild(link);
              }
    }
  };

  const handleChangeType = (option: any) => {
    setSelectedType(option);
};

  return (
    <div ref={rootRef} className="group-modal-container">
      <Modal
        show={openModal}
        size="2xl"
        className="group-modal"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        //dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
        <div className="custom-date-range-modal date-range48 mt-4 relative">
                            <Flatpickr
                                ref={flatpickrRef}
                                value={dateFilter}
                                onChange={(e: any) => handleChangeDate(e)}
                                options={
                                    {
                                        mode: 'range',
                                        dateFormat: 'M-d-Y'
                                    }
                                }
                                placeholder="Select Date"
                            />
                            {fDate !== '' && tDate !== '' ?
                                <button onClick={handleClearSelection}><HiOutlineX/></button> : <HiOutlineCalendar/>}
              </div>
            <div className="mt-4 relative flex md:flex-row flex-col">
                <MultiSelect
                        onChange={handleChangeType}
                        options={typeOptions}
                        value={selectedType}
                        name="type"
                        noOptionsMessage={() => t('No options')}
                        closeMenuOnSelect
                        isHolderLabel={true}
                        placeholder={`${t('orderPage.location')}`}
                    />
            </div>
            <GroupButton
              className="items-center justify-center pt-5 mb-4"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'button',
                  text: t('modal.export'),
                  classType: 'blue',
                  action: () => handleExportItem(),
                },
              ]}
            />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ExportStockLedgerModal;
